
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: String,
  },

  mounted() {},

  methods: {},

  data: () => {
    return {};
  },
});
