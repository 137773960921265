
import { defineComponent } from 'vue';
import Back from '../component/Back.vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  components: { Back },
  async mounted() {
    await RestApi.gmb.post();
    // ChIJbdR8miyVWUERyw-NmKxdQvM
  },
  methods: {
    add(business: any) {
      this.businessList.push({
        placeId: business.place_id,
        address: business.formatted_address,
      });
      this.searchInput = '';
      this.isShowResults = false;
      this.searchResult = [];
    },
    async handleInput() {
      if (!this.searchInput) {
        this.searchResult = [];
        return;
      }
      this.searchResult = await RestApi.gmb.search(this.searchInput);
    },
  },
  data: () => {
    return {
      searchInput: '',
      searchResult: [] as any,
      businessList: [] as any,
      isShowResults: false,
      selectedBusiness: null,
    };
  },
});
