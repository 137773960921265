import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Dashboard from '../page/Dashboard.vue';
import Result from '../page/Result.vue';
import Photo from '../page/Photo.vue';
import History from '../page/History.vue';
import Optimization from '../page/Optimization.vue';
import OptimizationResult from '../page/OptimizationResult.vue';
import PhotoEditor from '../page/editor/PhotoEditor.vue';
import Tags from '../page/editor/Tags.vue';
import Store from '../page/Store.vue';
import GmbCheck from '../page/GmbCheck.vue';
import Profile from '../page/Profile.vue';
import Landing from '../page/Landing.vue';
import Terms from '../page/Terms.vue';
import Policy from '../page/Policy.vue';
import Support from '../page/Support.vue';

import Admin from '../page/admin/Admin.vue';
import AdminUser from '../page/admin/User.vue';
import AdminLogs from '../page/admin/Logs.vue';

import SignIn from '../page/sign/SignIn.vue';
import SignUp from '../page/sign/SignUp.vue';
import Recover from '../page/sign/Recover.vue';

import WithoutCDN from '../page/test/WithoutCDN.vue';
import WithCDN from '../page/test/WithCDN.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: Landing,
  },

  {
    path: '/sign-in',
    name: 'Sign In',
    component: SignIn,
  },
  {
    path: '/sign-up',
    name: 'Sign Up',
    component: SignUp,
  },
  {
    path: '/recover',
    name: 'Recover',
    component: Recover,
  },

  {
    path: '/result/:id',
    name: 'Result',
    component: Result,
  },
  {
    path: '/optimization-result/:id',
    name: 'OptimizationResult',
    component: OptimizationResult,
  },
  {
    path: '/photo/:photoId/:resultId',
    name: 'Photo',
    component: Photo,
  },
  {
    path: '/history',
    name: 'History',
    component: History,
  },
  {
    path: '/optimization/:optimizationId',
    name: 'Optimization',
    component: Optimization,
  },
  {
    path: '/editor/:optimizationId',
    name: 'Editor',
    component: PhotoEditor,
  },
  {
    path: '/tags/:optimizationId',
    name: 'Tags',
    component: Tags,
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Store,
  },
  {
    path: '/gmb',
    name: 'GMB',
    component: GmbCheck,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/admin/user/:user',
    name: 'User',
    component: AdminUser,
  },
  {
    path: '/admin/logs',
    name: 'Logs',
    component: AdminLogs,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
  },

  {
    path: '/test/without_cdn',
    name: '',
    component: WithoutCDN,
  },
  {
    path: '/test/with_cdn',
    name: '',
    component: WithCDN,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
