
import { Options, Vue } from 'vue-class-component';
import { RestApi } from '../util/RestApi';
import { Loader } from 'google-maps';

@Options({
  components: {},
})
export default class Photo extends Vue {
  resultId = '';
  photoId = '';

  currentTab = 'webDetection';
  tabs = [
    { name: 'webDetection', title: 'Web Entities' },
    { name: 'label', title: 'Label' },
    { name: 'location', title: 'Location' },
    { name: 'safeSearch', title: 'Safe Search' },
    { name: 'text', title: 'Text' },
    { name: 'match', title: 'Image Match' },
  ];

  photoUrl = '';
  labels = [];
  safeSearch = {};
  webEntities = [] as { entityId: string }[];
  bestLabel = '';
  fullMatch = [];
  partialMatch = [];
  pages = [];
  landmarks = [];
  location = {} as any;
  description = '';
  text = [];
  photoSize = {
    width: 0,
    height: 0,
  };

  safeSearchItems = ['adult', 'medical', 'spoof', 'violence', 'racy'];
  safeSearchTerms = ['VERY_UNLIKELY', 'UNLIKELY', 'POSSIBLE', 'LIKELY', 'VERY_LIKELY'];

  async mounted() {
    this.resultId = this['$route'].params.resultId as string;
    this.photoId = this['$route'].params.photoId as string;

    const photo = await RestApi.photo.get(this.photoId);

    this.photoUrl = photo.url;
    this.labels = photo.info?.labelList || [];
    this.safeSearch = photo.info?.safeSearch;
    this.webEntities = photo.info?.webEntityList || [];
    this.bestLabel = photo.info?.bestLabel;
    this.fullMatch = photo.info?.fullMatch || [];
    this.partialMatch = photo.info?.partialMatch || [];
    this.pages = photo.info?.pages || [];
    this.description = photo.info?.description;
    this.location = photo.info?.landmarkList[0]?.location;
    this.landmarks = photo.info?.landmarkList || [];
    this.text = photo.info?.textList || [];

    this.initMap();

    const image = this.$refs['photo'] as HTMLImageElement;
    image.onload = () => {
      this.photoSize = {
        width: image?.naturalWidth || 0,
        height: image?.naturalHeight || 0,
      };
    };
  }

  selectTab(tab: string) {
    this.currentTab = tab;
  }

  async optimize() {
    const id = await RestApi.optimization.create(this.resultId, [this.photoId]);
    this['$router'].push(`/editor/${id}`);
  }

  initMap() {
    if (!this.location) {
      return;
    }

    // Init map
    const loader = new Loader('AIzaSyAOLXAhCvFoI-JglJJ_XG3Ib_6hlgrtm9o', {});
    loader.load().then((google) => {
      const map = new google.maps.Map(document.getElementById('map') as any, {
        center: { lat: this.location[0], lng: this.location[1] },
        zoom: 12,
      });

      const m = new google.maps.Marker({
        position: { lat: this.location[0], lng: this.location[1] },
        map,
        title: this.description,
      });
    });
  }
}
