
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: Boolean,
  },

  mounted() {},

  methods: {
    click() {
      const x = !this.$props.modelValue;
      this.$emit('update:modelValue', x);
      this.$emit('change', x);
    },
  },
});
