
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {},
})
export default class Header extends Vue {
  isShowMenu = false;

  mounted() {
    // console.log(process.env.VUE_APP_API_URL);
  }

  logout() {
    localStorage.removeItem('accessToken');
    window.location.reload();
  }
}
