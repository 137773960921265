import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: _ctx.$style.container
  }, [
    _createVNode("div", {
      class: _ctx.$style.form
    }, [
      _createVNode("div", { innerHTML: _ctx.message }, null, 8, ["innerHTML"]),
      _createVNode("div", {
        class: _ctx.$style.group
      }, [
        _createVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
          class: "red"
        }, "Cancel"),
        _createVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm'), _ctx.$emit('close'))),
          class: "green"
        }, "Confirm")
      ], 2)
    ], 2)
  ], 2))
}