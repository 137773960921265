import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.state === 'loaded')
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: _ctx.checkTheme()
        }, [
          _createVNode(_component_Header),
          _createVNode(_component_router_view),
          _createVNode(_component_Footer)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.state === 'loading')
      ? (_openBlock(), _createBlock("div", _hoisted_1, "..."))
      : _createCommentVNode("", true)
  ], 64))
}