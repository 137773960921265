import Axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export const RestApi = {
  store: {
    async getProductList(): Promise<any> {
      return (await Axios.get(`${API_URL}/store/list`)).data.response;
    },
    async addPaymentMethod(id: string): Promise<void> {
      return (
        await Axios.post(`${API_URL}/store/paymentMethod`, {
          id,
        })
      ).data.response;
    },
    async subscribe(id: string): Promise<void> {
      return (
        await Axios.post(`${API_URL}/store/subscribe`, {
          id,
        })
      ).data.response;
    },
    async unsubscribe(): Promise<any> {
      return (await Axios.post(`${API_URL}/store/unsubscribe`, {})).data.response;
    },
    async changePlan(id: string): Promise<any> {
      return (
        await Axios.post(`${API_URL}/store/changePlan`, {
          id,
        })
      ).data.response;
    },
    async cancelSubscription(id: string): Promise<any> {
      return (
        await Axios.post(`${API_URL}/store/cancelSubscription`, {
          id,
        })
      ).data.response;
    },
  },
  support: {
    async sendMessage(message: string): Promise<any> {
      return (
        await Axios.post(`${API_URL}/support/message`, {
          message,
        })
      ).data.response;
    },
  },
  admin: {
    async getUserList(): Promise<any> {
      return (await Axios.get(`${API_URL}/admin/userList`)).data.response;
    },
    async getUser(email: string): Promise<any> {
      return (await Axios.get(`${API_URL}/admin/user?id=${email}`)).data.response;
    },
    async addBalance(id: string, balance: number): Promise<any> {
      return (
        await Axios.patch(`${API_URL}/admin/balance`, {
          id,
          balance,
        })
      ).data.response;
    },
    async setRole(id: string, role: string): Promise<any> {
      return (
        await Axios.patch(`${API_URL}/admin/role`, {
          id,
          role,
        })
      ).data.response;
    },
    async addProduct(email: string, id: string): Promise<any> {
      return (await Axios.post(`${API_URL}/admin/userProduct?email=${email}&productId=${id}`)).data
        .response;
    },
    async removeProduct(email: string, transactionId: string): Promise<any> {
      return (
        await Axios.delete(
          `${API_URL}/admin/userProduct?email=${email}&transactionId=${transactionId || ''}`,
        )
      ).data.response;
    },
    async getLogs() {
      return (await Axios.get(`${API_URL}/admin/logs`)).data.response;
    },
  },
  photo: {
    async get(id: string): Promise<any> {
      return (await Axios.get(`${API_URL}/photo?id=${id}`)).data.response;
    },

    async getCompressedSize(id: string): Promise<number> {
      return (await Axios.get(`${API_URL}/photo/compressedSize?id=${id}`)).data.response;
    },

    async upload(resultId: string, files: File[], onProgess: (s: any) => void): Promise<string> {
      const formData = new FormData();
      files.forEach((x: File, i: number) => {
        formData.append(`image_${i}`, x, x.name);
      });

      return (
        await Axios.post(`${API_URL}/photo?resultId=${resultId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: onProgess,
        })
      ).data.response;
    },

    async updateTags(id: string, fields: any): Promise<void> {
      await Axios.post(`${API_URL}/photo/tags`, {
        id,
        tags: fields,
      });
    },

    async crop(id: string, rotation: number, area: number[]): Promise<void> {
      await Axios.post(`${API_URL}/photo/crop`, {
        id,
        rotation,
        x: area[0],
        y: area[1],
        width: area[2],
        height: area[3],
      });
    },

    async resize(id: string, width: number, height: number): Promise<void> {
      return await Axios.post(`${API_URL}/photo/resize`, {
        id,
        width,
        height,
      });
    },

    async compress(id: string): Promise<void> {
      return await Axios.post(`${API_URL}/photo/compress`, {
        id,
      });
    },

    async reset(id: string): Promise<void> {
      return await Axios.post(`${API_URL}/photo/reset`, {
        id,
      });
    },

    /*async updateConfig(
      photoId: string,
      isCrop: boolean,
      isCompress: boolean,
      quality: number,
      convertTo: string,
      width: number,
      height: number,
      rotation: number,
      flipX: boolean,
      flipY: boolean,
      crop: [number, number, number, number, number, number, number, number],
    ): Promise<void> {
      await Axios.post(`${API_URL}/photo/updateConfig`, {
        photoId,
        isCrop,
        isCompress,
        quality,
        convertTo,
        width,
        height,
        rotation,
        flipX,
        flipY,
        cropX: crop[0],
        cropY: crop[1],
        cropW: crop[2],
        cropH: crop[3],
        cropXP: crop[4],
        cropYP: crop[5],
        cropWP: crop[6],
        cropHP: crop[7],
      });
    },*/
  },
  result: {
    async get(id: string): Promise<any> {
      return (await Axios.get(`${API_URL}/result/index?id=${id}`)).data.response;
    },
    async getPhotoList(id: string): Promise<any> {
      let list = (await Axios.get(`${API_URL}/result/photoList?id=${id}`)).data.response || [];
      list = list.map((x: any) => {
        x.selected = false;
        return x;
      });
      return list;
    },
    async create() {
      return (await Axios.post(`${API_URL}/result`)).data.response;
    },
    /*async getPhotoInfo(resultId: string, photoId: string): Promise<any> {
      return (await Axios.get(`${API_URL}/result/photo?resultId=${resultId}&photoId=${photoId}`))
        .data.response;
    },*/
    async deletePhoto(resultId: string, photoId: string) {
      return (await Axios.delete(`${API_URL}/result/photo?id=${resultId}&photoId=${photoId}`)).data
        .response;
    },
  },
  user: {
    async check(): Promise<any> {
      return (await Axios.get(`${API_URL}/user/check`)).data.response;
    },
    async info(): Promise<any> {
      return (await Axios.get(`${API_URL}/user`)).data.response;
    },
    async signIn(email: string, password: string): Promise<string> {
      return (
        await Axios.post(`${API_URL}/user/signIn`, {
          email,
          password,
        })
      ).data.response.accessToken;
    },
    async signUp(
      email: string,
      password1: string,
      password2: string,
      firstName: string,
      lastName: string,
      secret: string,
    ): Promise<string> {
      return (
        await Axios.post(`${API_URL}/user/signUp`, {
          email,
          password1,
          password2,
          firstName,
          lastName,
          secret,
        })
      ).data.response.accessToken;
    },
    async processingHistory(): Promise<any> {
      return (await Axios.get(`${API_URL}/user/processingHistory`, {})).data.response;
    },
    async optimizationHistory(): Promise<any> {
      return (await Axios.get(`${API_URL}/user/optimizationHistory`, {})).data.response;
    },
    async getSubscriptionList(): Promise<any> {
      try {
        return (await Axios.get(`${API_URL}/user/subscriptionList`, {})).data.response;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async paymentMethodList(): Promise<any> {
      return (await Axios.get(`${API_URL}/user/paymentMethodList`, {})).data.response;
    },
  },
  preset: {
    async get(id: string): Promise<any> {
      return (await Axios.get(`${API_URL}/preset?id=${id}`, {})).data.response;
    },
    async add(name: string, fields: any): Promise<void> {
      await Axios.post(`${API_URL}/preset`, {
        name,
        tags: fields,
      });
    },
    async update(id: string, name: string, fields: any): Promise<void> {
      await Axios.patch(`${API_URL}/preset?id=${id}`, {
        name,
        tags: fields,
      });
    },
    async delete(id: string): Promise<void> {
      await Axios.delete(`${API_URL}/preset?id=${id}`, {});
    },
    async list(): Promise<{ id: string; name: string }[]> {
      return (await Axios.get(`${API_URL}/preset/list`)).data.response;
    },
  },
  optimization: {
    /*async getZip(id: string): Promise<any> {
      const optimization = (await Axios.get(`${API_URL}/optimization/zip?id=${id}`)).data.response;
      return optimization;
    },*/
    async get(id: string): Promise<any> {
      const optimization = (await Axios.get(`${API_URL}/optimization?id=${id}`)).data.response;
      /*optimization.photoList = optimization.photoList.map((x: any) => {
        x.path = `${PHOTO_URL}${x.path.replace('../data', '/photo')}`;
        x.thumbnail = `${PHOTO_URL}${x.thumbnail.replace('../data', '/photo')}`;
        return x;
      });*/
      return optimization;
    },
    async getPhotoList(id: string): Promise<any> {
      return (await Axios.get(`${API_URL}/optimization/photoList?id=${id}`)).data.response;
    },
    async create(resultId: string, photoIdList: string[]): Promise<string> {
      return (
        await Axios.post(`${API_URL}/optimization/index`, {
          resultId,
          photoIdList,
        })
      ).data.response;
    },
    async prepare(id: string): Promise<void> {
      return (
        await Axios.post(`${API_URL}/optimization/prepare`, {
          id,
        })
      ).data.response;
    },
    async run(id: string): Promise<string> {
      return (
        await Axios.post(`${API_URL}/optimization/run`, {
          id,
        })
      ).data.response;
    },
    async deletePhoto(optimizationId: string, photoId: string) {
      return (
        await Axios.delete(
          `${API_URL}/optimization/photo?optimizationId=${optimizationId}&photoId=${photoId}`,
        )
      ).data.response;
    },
  },
  other: {
    async version(): Promise<string> {
      return (await Axios.get(`${API_URL}/other/version`)).data.response;
    },
  },
  gmb: {
    async search(query: string): Promise<string[]> {
      return (await Axios.get(`${API_URL}/gmb/search?query=${query}`)).data.response;
    },
    async post(): Promise<void> {
      return (await Axios.post(`${API_URL}/gmb/run`, {})).data.response;
    },
  },
};
