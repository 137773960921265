
import { defineComponent } from 'vue';
import { RestApi } from '../../util/RestApi';
import Confirm from '../../component/Confirm.vue';

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default defineComponent({
  components: {
    VueCropper,
    Confirm,
  },
  async mounted() {
    this.optimizationId = this['$route'].params.optimizationId as string;

    this.isLoading = true;
    await RestApi.optimization.prepare(this.optimizationId);
    this.isLoading = false;

    this.photoList = await RestApi.optimization.getPhotoList(this.optimizationId);
    this.selectPhoto(0);
  },
  methods: {
    selectPhoto(id: number) {
      this.currentPhotoId = id;
      this.resize.width = this.photoList[id].resolution[0];
      this.resize.height = this.photoList[id].resolution[1];
      this.r = Math.random();
      this.updateCrop = false;

      setTimeout(() => {
        this.updateCrop = true;
        this.isCropReady = false;
      });
    },

    setEditMode(mode: string) {
      // Cancel previous
      this.cancel();

      this.editMode = mode;

      this.updateCrop = false;
      setTimeout(() => {
        this.updateCrop = true;
        this.isCropReady = false;
      });
    },

    cropReady() {
      this.isCropReady = true;

      const w = (this.$refs['cropper'] as any).$el.querySelector('img').width;
      const h = (this.$refs['cropper'] as any).$el.querySelector('img').height;

      (this.$refs['cropper'] as any).setData({
        x: 0,
        y: 0,
        width: w,
        height: h,
      });
    },

    handleCrop(e: any) {
      if (!this.isCropReady) {
        return;
      }

      if ((this.$refs['cropper'] as any)?.$el) {
        const w = (this.$refs['cropper'] as any).$el.querySelector('img').width;
        const h = (this.$refs['cropper'] as any).$el.querySelector('img').height;

        this.crop.area[0] = e.detail.x / w;
        this.crop.area[1] = e.detail.y / h;
        this.crop.area[2] = (e.detail.x + e.detail.width) / w;
        this.crop.area[3] = (e.detail.y + e.detail.height) / h;
      }
    },

    async refreshPhoto(photoId: number) {
      this.photoList[photoId] = await RestApi.photo.get(this.photoList[photoId].id);
      this.selectPhoto(photoId);
    },

    cancel() {
      this.editMode = '';
      this.crop.rotation = 0;
      this.selectPhoto(this.currentPhotoId);
    },

    currentPhotoUrl() {
      return (
        this.photoList[this.currentPhotoId].diskPath +
        `?quality=80&width=${Math.min(this.resize.width, 960)}&rotation=${this.crop.rotation}&r=${
          this.r
        }`
      );
    },

    async changeResolution(type: string) {
      if (type === 'width') {
        const widthChange =
          eval(this.resize.width + '') / this.photoList[this.currentPhotoId].resolution[0];
        this.resize.height = (this.photoList[this.currentPhotoId].resolution[1] * widthChange) | 0;
        this.resize.width = eval(this.resize.width + '');
      } else {
        const heightChange =
          eval(this.resize.height + '') / this.photoList[this.currentPhotoId].resolution[1];
        this.resize.width = (this.photoList[this.currentPhotoId].resolution[0] * heightChange) | 0;
        this.resize.height = eval(this.resize.height + '');
      }
    },

    async rotatePhoto(angle: number) {
      this.crop.rotation += angle;
      this.crop.rotation = this.crop.rotation % 360;
      this.selectPhoto(this.currentPhotoId);
    },

    async resetToOriginal() {
      this.isLoading = true;
      await RestApi.photo.reset(this.photoList[this.currentPhotoId].id);
      await this.refreshPhoto(this.currentPhotoId);
      this.isLoading = false;
    },

    async showCompress() {
      const size = await RestApi.photo.getCompressedSize(this.photoList[this.currentPhotoId].id);
      this.modal.isShow = true;
      this.modal.message = `
          <div>Compress this photo?</div>
          <div>New size: <b>${~~(size / 1024)} KB</b></div>
          <div>Previous size: <b>${~~(this.photoList[this.currentPhotoId].size / 1024)} KB</b></div>
          <div>Saving: ${~~((1 - size / this.photoList[this.currentPhotoId].size) * 100)}%</div>
        `;
      this.modal.method = () => {
        this.done('compress');
      };
    },

    async finish() {
      this.isSaving = true;
      await RestApi.optimization.run(this.optimizationId);
      this.$router.push(`/optimization-result/${this.optimizationId}`);
    },

    async done(type: string) {
      if (type === 'resize') {
        await RestApi.photo.resize(
          this.photoList[this.currentPhotoId].id,
          this.resize.width,
          this.resize.height,
        );
        this.photoList[this.currentPhotoId].isChanged = true;
      }

      if (type === 'crop') {
        await RestApi.photo.crop(
          this.photoList[this.currentPhotoId].id,
          this.crop.rotation,
          this.crop.area,
        );
        this.photoList[this.currentPhotoId].isChanged = true;
        this.crop.rotation = 0;
      }

      if (type === 'compress') {
        await RestApi.photo.compress(this.photoList[this.currentPhotoId].id);
        this.photoList[this.currentPhotoId].isChanged = true;
        this.photoList[this.currentPhotoId].isCompressed = true;
      }

      this.editMode = '';
      await this.refreshPhoto(this.currentPhotoId);
    },
  },
  data() {
    return {
      optimizationId: '',
      currentPhotoId: 0,
      photoList: [] as any[],

      updateCrop: false,
      isCropReady: false,
      isSaving: false,
      isLoading: false,

      editMode: '',

      resize: {
        width: 0,
        height: 0,
      },

      crop: {
        rotation: 0,
        area: [0, 0, 1, 1],
      },

      modal: {
        isShow: false,
        message: '',
        method: () => {},
      },

      r: Math.random(),
    };
  },
});
