
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';
import Checkbox from '../component/Checkbox.vue';
import Back from '../component/Back.vue';
import MessageBox from '../component/MessageBox.vue';

export default defineComponent({
  components: {
    Checkbox,
    Back,
    MessageBox,
  },
  async mounted() {
    this.refresh();
  },
  methods: {
    async optimize() {
      const selected = this.photoList.filter((x: any) => x.selected).map((x: any) => x.id);

      if (selected.length > (this.$root as any).user.balance) {
        this.message = `You trying to optimize <b>${
          selected.length
        }</b> photos. But your balance is <b>${
          (this.$root as any).user.balance
        }</b>. It's not enough to handle all photos. Please fund your account to continue.`;
        this.isShowMessage = true;
        return;
      }

      const id = await RestApi.optimization.create(
        this['$route'].params.id as string,
        this.photoList.filter((x: any) => x.selected).map((x: any) => x.id),
      );
      this.$router.push(`/editor/${id}`);
    },
    async refresh() {
      try {
        const resultId = this['$route'].params.id as string;
        const result = await RestApi.result.getPhotoList(resultId);
        this.id = resultId;
        this.photoList = result;
      } catch {
        this.photoList = [];
        this.isNotFound = true;
      }
    },
    async deletePhoto(id: string) {
      if (confirm('Are you sure?')) {
        await RestApi.result.deletePhoto(this.id, id);
        this.refresh();
      }
    },
    async deleteSelected() {
      if (confirm('Are you sure?')) {
        const selected = this.photoList.filter((x: any) => x.selected);
        for (let i = 0; i < selected.length; i++) {
          await RestApi.result.deletePhoto(this.id, selected[i].id);
        }
        this.refresh();
      }
    },
  },
  data: (): any => {
    return {
      id: '',
      photoList: [],
      isNotFound: false,
      isShowMessage: false,
      message: '',
    };
  },
});
