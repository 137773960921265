
import { defineComponent } from 'vue';
import { RestApi } from '../../util/RestApi';
declare var grecaptcha: any;

export default defineComponent({
  components: {},
  async mounted() {
    setTimeout(() => {
      grecaptcha.render('sas', {
        // @ts-ignore
        sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      });
    }, 1000);
  },
  methods: {
    async signUp() {
      this.isError = false;

      try {
        const accessToken = await RestApi.user.signUp(
          this.email,
          this.password_1,
          this.password_2,
          this.firstName,
          this.lastName,
          grecaptcha.getResponse(),
        );
        localStorage.setItem('accessToken', accessToken);
        window.location.href = '/';
      } catch (e) {
        this.isError = true;
        this.description = e.response?.data?.error?.description;
      }
    },
  },
  data: () => {
    return {
      isError: false,
      firstName: '',
      lastName: '',
      email: '',
      password_1: '',
      password_2: '',
      description: '',
    };
  },
});
