
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  props: {
    title: String,
  },
  async mounted() {
    this.version = await RestApi.other.version();
  },
  methods: {},
  data: () => {
    return {
      version: '',
    };
  },
});
