
import { defineComponent } from 'vue';
import { RestApi } from '../../util/RestApi';
import Back from '../../component/Back.vue';
import TagEditor from '../../component/TagEditor.vue';
import Select from '../../component/Select.vue';
import Checkbox from '../../component/Checkbox.vue';
import Confirm from '../../component/Confirm.vue';
import PresetEditor from '../../component/PresetEditor.vue';

export default defineComponent({
  components: { Back, Select, Checkbox, Confirm, PresetEditor, TagEditor },
  async mounted() {
    this.optimizationId = this['$route'].params.optimizationId as string;

    this.refreshPhotoList();
    this.refreshPresetList();
  },
  methods: {
    async refreshPhotoList() {
      this.photoList = await RestApi.optimization.getPhotoList(this.optimizationId);
    },

    async refreshPresetList() {
      this.presetList = [...(await RestApi.preset.list())];
      (this.$refs['tag-editor'] as any)?.refresh();
    },

    closeModal() {
      this.isShowModal = false;
    },

    showModal(text: string, fn: (...args: any[]) => void, args: any[] = []) {
      this.isShowModal = true;
      this.modalText = text;
      this.modalConfirmFunction = fn;
      this.modalArgs = args || [];
    },

    editPreset(id: string) {
      this.isShowPresetEditor = true;
      this.editPresetId = id;
    },

    canShowAddPreset() {
      if ((this.$root as any).user?.subscriptionLevel === 0) {
        return false;
      }
      if ((this.$root as any).user?.subscriptionLevel === 1) {
        return this.presetList.length <= 0;
      }
      if ((this.$root as any).user?.subscriptionLevel === 2) {
        return this.presetList.length <= 9;
      }
      return true;
    },

    async deletePreset(isConfirmed = false, preset: { value: string }) {
      if (isConfirmed) {
        await RestApi.preset.delete(preset.value);
        this.refreshPresetList();
      } else {
        this.showModal(`Are you sure you want to delete this preset?`, this.deletePreset, [preset]);
      }
    },

    async savePreset() {
      const name = prompt(`Enter preset name...`);
      if (name) {
        await RestApi.preset.add(name, this.photoList[this.currentPhotoId].tags);
      }
    },

    async applyPresetToSelected(isConfirmed = false, id: string, mode = 'amend') {
      // Get preset
      const preset = this.presetList.find((x: any) => x.id === id);

      // Get selected photos
      const selected = this.photoList.filter((x: any) => x.selected);
      if (!selected.includes(this.photoList[this.currentPhotoId])) {
        selected.push(this.photoList[this.currentPhotoId]);
      }

      // Warning is select more than 1 photo
      if (selected.length > 1 && !isConfirmed) {
        this.showModal(
          `Are you sure you want to apply preset to all selected photos?`,
          this.applyPresetToSelected,
          [id, mode],
        );
        return;
      }

      if (preset) {
        // Get each photo
        for (let i = 0; i < selected.length; i++) {
          if (!selected[i].tags) {
            selected[i].tags = {};
          }

          for (let key in preset.tags) {
            if (mode === 'amend') {
              if (!selected[i].tags[key]) {
                selected[i].tags[key] = preset.tags[key];
              }
            } else {
              selected[i].tags[key] = preset.tags[key];
            }
          }
        }

        // Save on server
        for (let i = 0; i < selected.length; i++) {
          selected[i].selected = false;
          await RestApi.photo.updateTags(selected[i].id, selected[i].tags);
        }

        // Refresh
        this.selectedPreset = null;
        this.refreshPhotoList();
        this.refreshPresetList();
      }
    },
  },
  data: () => {
    return {
      isShowPresetEditor: false,
      editPresetId: '',

      isShowModal: false,
      modalText: '',
      modalArgs: [] as any[],
      modalConfirmFunction: (...args: any[]) => {},

      photoListCopy: [] as any,
      photoList: [] as any,
      selectedPhoto: [] as number[],
      currentPhotoId: 0,
      optimizationId: '',
      presetList: [] as any,
      selectedPreset: null,
    };
  },
});
