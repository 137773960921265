
import Axios from 'axios';
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  components: {},
  async mounted() {
    const optimizationId = this['$route'].params.id as string;
    this.id = optimizationId;
    this.photoList = await RestApi.optimization.getPhotoList(optimizationId);
  },
  methods: {
    async downloadZip(format = 'jpeg') {
      this.isLoading = true;

      // @ts-ignore
      const url = `${process.env.VUE_APP_API_URL}/optimization/zip?id=${this.id}&format=${format}`;
      const blob = await Axios.get(url, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      });
      const a = document.createElement('a');
      const href = window.URL.createObjectURL(blob.data);
      a.href = href;
      a.download = `photos_${this.id}.zip`;
      a.click();

      this.isLoading = false;
    },
    async download(photoId: string, photo: any, format = 'jpeg') {
      this.isLoading = true;

      // @ts-ignore
      const url = `${process.env.VUE_APP_API_URL}/photo/converted?id=${photoId}&format=${format}`;
      const blob = await Axios.get(url, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      });
      const a = document.createElement('a');
      const href = window.URL.createObjectURL(blob.data);
      a.href = href;
      if (photo?.tags?.fileName) {
        a.download = `${photo?.tags?.fileName}.${format}`;
      } else {
        a.download = `${photoId}.${format}`;
      }
      a.click();

      this.isLoading = false;
    },
    /*async download(url: string, photo: any) {
      const blob = await Axios.get(url, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      });
      const a = document.createElement('a');
      const href = window.URL.createObjectURL(blob.data);
      a.href = href;
      if (photo?.info?.copyright?.filename) {
        a.download = `${photo?.info?.copyright?.filename}.jpeg`;
      } else {
        a.download = url.split('/').pop() || 'photo.jpeg';
      }

      a.click();
      console.log(photo);
    },*/
  },
  data: (): { id: string; photoList: { id: string; url: string }[]; isLoading: boolean } => {
    return {
      id: '',
      photoList: [],
      isLoading: false,
    };
  },
});
