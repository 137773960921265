
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  components: {},
  async mounted() {},
  methods: {
    async send() {
      this.isSend = false;
      try {
        await RestApi.support.sendMessage(this.message);
        this.isSend = true;
      } catch {
        alert(
          `Can't send message. Try to write to support@imageranks.com manally or repeat later.`,
        );
      }
    },
  },
  data: (): any => {
    return {
      message: '',
      isSend: false,
    };
  },
});
