
import { defineComponent } from 'vue';
import Header from './component/Header.vue';
import Footer from './component/Footer.vue';
import { RestApi } from './util/RestApi';
import Dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Axios from 'axios';
Dayjs.extend(RelativeTime);

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  async mounted() {
    // Check user info
    try {
      this.user = await RestApi.user.check();
    } catch {}

    try {
      this.user = await RestApi.user.info();
      this.state = 'loaded';
      this.isAuth = true;
    } catch (e) {
      this.state = 'loaded';
      this.isAuth = false;
    }

    this.$router.beforeEach((to: any, from: any, next: any) => {
      if (this.user.role !== 'admin') {
        if (to.path.match('/admin')) {
          next(false);
          return;
        }
      }

      if (this.user.balance <= 0) {
        if (to.path.match('/editor')) {
          next(false);
          return;
        }
      }

      if (this.isAuth) {
        next();
        this.refreshUserInfo();
      } else {
        if (
          to.path === '/test/without_cdn' ||
          to.path === '/test/with_cdn' ||
          to.path === '/sign-in' ||
          to.path === '/sign-up' ||
          to.path === '/recover' ||
          to.path === '/'
        ) {
          next();
        } else {
          next(false);
        }
      }
    });

    if (!this.isAuth) {
      if (
        !['/sign-in', '/sign-up', '/recover', '/', '/test/without_cdn', '/test/with_cdn'].includes(
          this.$router.currentRoute.value.fullPath,
        )
      ) {
        this.$router.push(`/`);
      }
    } else {
      if (this.user.role !== 'admin') {
        if (this.$router.currentRoute.value.fullPath.match('/admin')) {
          this.$router.push('/');
        }
      }

      if (this.user.balance <= 0) {
        if (this.$router.currentRoute.value.fullPath.match('/editor')) {
          this.$router.push('/');
        }
      }
    }
  },
  methods: {
    checkTheme() {
      const darkPages = ['editor', 'tags'];
      if (darkPages.includes(this.$router.currentRoute.value.path.split('/')[1])) {
        document.getElementsByTagName('body')[0].setAttribute('class', 'theme');
      } else {
        document.getElementsByTagName('body')[0].setAttribute('class', 'theme');
      }
    },
    showHeader() {
      const darkPages = ['editor', 'tags'];
      if (darkPages.includes(this.$router.currentRoute.value.path.split('/')[1])) {
        return false;
      } else {
        return true;
      }
    },
    async refreshUserInfo() {
      this.user = await RestApi.user.info();
    },
  },
  data: (): any => {
    return {
      user: {
        balance: 0,
        role: '',
      },
      state: 'loading',
      isAuth: false,
      moment: Dayjs,
    };
  },
});
