
import { defineComponent } from 'vue';
import { RestApi } from '../../util/RestApi';

export default defineComponent({
  async mounted() {
    const userId = this['$route'].params.user as string;
    this.user = await RestApi.admin.getUser(userId);
    // this.user.created = this.$root.moment(this.user.created).fromNow();
  },
  methods: {
    async addBalance() {},
    print(key: string, data: any) {
      if (Array.isArray(data)) return data.length;
      else return data;
    },
    async addProduct() {
      const id = prompt('Enter product id');
      if (id) {
        try {
          await RestApi.admin.addProduct(this.user.email, id.toLowerCase());
          window.location.reload();
        } catch {
          alert('Product not found!');
        }
      }
    },
    async removeProduct(transactionId: string) {
      if (confirm('Are you sure?')) {
        await RestApi.admin.removeProduct(this.user.email, transactionId);
        window.location.reload();
      }
    },
  },
  data: () => {
    return {
      user: {
        email: '',
        balance: 0,
        firstName: '',
        lastName: '',
        created: '',
        photoList: [],
        resultList: [],
        optimizationList: [],
      },
    };
  },
});
