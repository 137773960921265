
import { defineComponent } from 'vue';
import { RestApi } from '../../util/RestApi';

export default defineComponent({
  async mounted() {
    this.logs = await RestApi.admin.getLogs();
    console.log(this.logs);
  },
  methods: {},
  data: () => {
    return {
      logs: [] as any[],
    };
  },
});
