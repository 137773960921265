
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';
import Select from './Select.vue';
import Confirm from './Confirm.vue';

export default defineComponent({
  components: { Select, Confirm },
  props: {
    photo: Object,
    preset: Object,
  },

  async mounted() {
    await this.refreshPresetList();
    if (this.photo) {
      this.tags = JSON.parse(JSON.stringify(this.photo.tags));
    }
  },

  watch: {
    photo(data: any) {
      this.tags = JSON.parse(JSON.stringify(data.tags));
    },
    preset(data: any) {},
  },

  methods: {
    closeModal() {
      this.isShowModal = false;
    },

    showModal(text: string, fn: (...args: any[]) => void, args: any[] = []) {
      this.isShowModal = true;
      this.modalText = text;
      this.modalConfirmFunction = fn;
      this.modalArgs = args || [];
    },
    async refreshPresetList() {
      this.presetList = [...(await RestApi.preset.list())];
    },
    checkChanges() {
      if (!this.photo) {
        return [];
      }
      const out = [];

      for (const key in this.tags) {
        if (JSON.stringify(this.photo.tags[key]) !== JSON.stringify(this.tags[key])) {
          out.push(key);
        }
      }
      return out;
    },
    cancel() {
      this.tags = JSON.parse(JSON.stringify(this.photo?.tags));
    },
    async clear(isConfirmed = false) {
      if (isConfirmed) {
        for (let key in this.tags) {
          this.tags[key] = '';
        }
        this.tags['copyright'] = ['', '', '', '', ''];
      } else {
        this.showModal(`Are you sure? This action is irreversible`, this.clear);
      }
    },
    async save(mode = 'amend') {
      if (!this.photo) {
        return;
      }

      await RestApi.photo.updateTags(this.photo.id, this.tags);

      this.$emit('refresh');
    },
    refresh() {
      this.refreshPresetList();
    },
  },

  data() {
    const subscriptionLevel = (this.$root as any).user.subscriptionLevel;
    return {
      isShowModal: false,
      modalText: '',
      modalArgs: [] as any[],
      modalConfirmFunction: (...args: any[]) => {},

      fields: [
        {
          name: 'General',
          items: [
            {
              name: 'fileName',
              type: 'string',
              hint: 'Example of completion: office_building_miami_001.jpeg',
            },
            { name: 'creator', type: 'string' },
            { name: 'headline', type: 'string' },
            { name: 'description', type: 'textarea' },
            { name: 'creditLine', type: 'string', title: 'Credit To' },
            { name: 'copyrightStatus', type: 'select', title: 'Сopyright Status' },
            { name: 'rightUsageTerms', type: 'string', title: 'Right Usage Terms' },
            { name: 'copyright', type: 'copyright' },

            {
              name: 'licensorUrl',
              type: 'string',
              title: 'Get This Image On (IPTC LIcensor URL)',
              hint: 'https://example.com/contact-us.html',
              isReadOnly: subscriptionLevel < 2,
            },
            {
              name: 'webstatement',
              type: 'string',
              title: 'License Details',
              hint: 'https://example.com/CopyrightPolicy.pdf',
              isReadOnly: subscriptionLevel < 2,
            },
          ],
        },
        {
          name: 'Contact info',
          items: [
            { name: 'country', type: 'string' },
            { name: 'state', type: 'string' },
            { name: 'city', type: 'string' },
            { name: 'postal', type: 'string', title: 'Postal/ZIP code' },
            { name: 'address', type: 'string' },
            { name: 'email', type: 'string' },
            { name: 'phone', type: 'string' },
            { name: 'website', type: 'string' },
          ],
        },
        {
          name: 'Other',
          items: [
            { name: 'latitude', type: 'number' },
            { name: 'longitude', type: 'number' },
            { name: 'keywords', type: 'string' },
            { name: 'altTextAccessibility', type: 'string' },
          ],
        },
      ],

      tags: {
        filename: '',
        creator: '',
        headline: '',
        description: '',
        creditLine: '',
        copyrightStatus: true,
        rightUsageTerms: '',
        copyright: ['© Copyright', `${new Date().getFullYear()}`, '', 'All Rights Reserved', ''],

        licensorUrl: '',
        webstatement: '',

        country: '',
        state: '',
        city: '',
        postal: '',
        address: '',
        phone: '',
        email: '',
        website: '',
      } as any,
      presetList: [] as any[],
      selectedPreset: '',
      presetCopy: {},
    };
  },
});
