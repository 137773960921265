
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: [String, Number, Boolean],
    list: Array,
    placeholder: String,
    select: Function,
    isDelete: Boolean,
    isEdit: Boolean,
  },

  mounted() {},

  methods: {
    click(item: string) {
      this.$emit('update:modelValue', item);
    },
    findValue(value: string) {
      return this.list?.find((x: any) => x.value === value);
    },
  },

  data: () => {
    return {
      isOpen: false,
    };
  },
});
