
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';
import { Swiper, SwiperSlide } from 'swiper/vue';
// @ts-ignore
import AOS from 'aos';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  async mounted() {
    if ((this.$root as any)?.user?.email) {
      this.$router.push('/dashboard');
    }

    this.currentReview = this.reviews[0];
    setTimeout(() => {
      AOS.init();
    }, 500);

    if (window.innerWidth <= 576) {
      this.swiperAmount = 1;
    }
  },
  methods: {},
  data: () => {
    return {
      swiperAmount: 3,
      secondBlock: [
        // { name: 'Best Guess Label', image: 'screen_2.png' },
        {
          name: 'Web Entities',
          image: 'screen_1.jpg',
          description: `
          Web entities, per Dave Davies from Search Engine Journal, are “the most important concept to
          understand in SEO right now. Full stop”.
          <br/>
          <br/>
          Image Ranks helps you to run any image or group of images through Google Vision AI to get an
          idea what entities your images wil be allocated to by Google after publishing on the websites or
          Google My Business.
        `,
        },
        {
          name: 'Labels',
          image: 'screen_2.jpg',
          description: `
          Image Label detection is another powerful tool every SEO professional should use.
          Image ranks provides you with a quick and easy way to detect the image labels the same way
          Google does (simply because we officially using their Image detection tools).
        `,
        },
        {
          name: 'Safe Search',
          image: 'screen_3.jpg',
          description: `
          One of the overlooked but important test for anyone who uses images on the web (well,
          everyone:) is to run your pictures through the Image safety check prior to publishing.
          Now you can do it with one click. Image Ranks uses Google AI technology to detect how possible
          safe or not the image is for five main categories: Adult, Medical, Violence, Racy, Spoof.
        `,
        },
        {
          name: 'Text Detection',
          image: 'screen_4.jpg',
          description: `
          Image ranks automatically detects the texts that are present on the images the same way Google
          does. Whether it’s small or large, part of the logo or watermark. Vertical or diagonal - you can prerun
          all your images and edit them accordingly prior to publishing.
        `,
        },
        {
          name: 'Location',
          image: 'screen_5.jpg',
          description: `
          The geo location not of every picture but some can be detected by Google simply from the image
          itself - by detection geo-specific landmarks, buildings, shapes etc. This AI-powered tool is
          available for you now.
        `,
        },
      ],
      currentReview: null as any,
      reviews: [
        {
          photo: 'client_1.png',
          name: 'Melissa Colton',
          description: 'Sales Manager',
          reviewTitle: `It was a great experience!`,
          reviewDescription: `Save lot of time and easy improve my SEO. I’m really happy to discover this service.
              Adding alt tags and renaming images is always 
              a pain and I often forget to do it. 
              Highly recommended.`,
        },
        {
          photo: 'client_2.png',
          name: 'Milton Austin',
          description: 'SEO Specialist',
          reviewTitle: `It was a great experience!`,
          reviewDescription: `Save lot of time and easy improve my SEO. I’m really happy to discover this service.
              Adding alt tags and renaming images is always 
              a pain and I often forget to do it. 
              Highly recommended.`,
        },
        {
          photo: 'client_3.png',
          name: 'Luke Harper',
          description: 'Product Manager',
          reviewTitle: `It was a great experience!`,
          reviewDescription: `Save lot of time and easy improve my SEO. I’m really happy to discover this service.
              Adding alt tags and renaming images is always 
              a pain and I often forget to do it. 
              Highly recommended.`,
        },
      ],
    };
  },
});
