
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  async mounted() {
    this.subscriptionList = await RestApi.user.getSubscriptionList();
    this.paymentMethodList = await RestApi.user.paymentMethodList();
  },
  methods: {
    async cancelSubscription(id: string) {
      await RestApi.store.cancelSubscription(id);
      window.location.reload();
    },
    async pay(url: string) {
      window.location.href = url;
    },
  },
  data: () => {
    return {
      fields: ['firstName', 'lastName', 'email', 'balance'],
      subscriptionList: [],
      paymentMethodList: [],
    };
  },
});
