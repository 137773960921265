
import { Options, Vue } from 'vue-class-component';
import { RestApi } from '../util/RestApi';
import MessageBox from '../component/MessageBox.vue';

@Options({
  components: {
    MessageBox,
  },
})
export default class Main extends Vue {
  isUpload = false;
  isDrag = false;
  isShowMessage = false;
  message = '';
  progress: number[] = [];
  done: boolean[] = [];
  preview: string[] = [];

  async handleFiles(files: FileList) {
    if (files.length > (this.$root as any).user.balance) {
      this.message = `You trying to upload <b>${files.length}</b> photos. But your balance is <b>${
        (this.$root as any).user.balance
      }</b>. It's not enough to handle all photos. Please fund your account to continue.`;
      this.isShowMessage = true;
      return;
    }

    const images = Array.from(files).filter(
      (x: File) => x.type.match(/^image\//) || x.name.toLowerCase().match('.heic'),
    );
    const resultId = await RestApi.result.create();
    this.isUpload = true;
    this.progress = images.map((x) => 0);
    this.done = images.map((x) => false);
    let amount = images.length;
    images.map((file, i) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          this.preview[i] = reader.result as string;
        },
        false,
      );
      reader.readAsDataURL(file);
    });

    for (let i = 0; i < images.length; i++) {
      (async () => {
        try {
          await RestApi.photo.upload(resultId, [images[i]], (e) => {
            this.progress[i] = e.loaded / images[i].size;
          });
          this.done[i] = true;
        } catch {}

        amount -= 1;
        if (amount <= 0) {
          this['$router'].push(`/result/${resultId}`);
        }
      })();
    }
  }

  selectImages() {
    if (this.isUpload) {
      return;
    }

    const fileInput = this.$refs['file-input'] as HTMLInputElement;

    if (fileInput) {
      fileInput.onchange = (e: any) => {
        this.handleFiles(e.target.files);
      };
      fileInput.click();
    }
  }

  dragOver(e: any) {
    if (this.isUpload) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    this.isDrag = true;
  }

  dragLeave() {
    if (this.isUpload) {
      return;
    }
    this.isDrag = false;
  }

  drop(e: any) {
    if (this.isUpload) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    this.isDrag = true;

    this.handleFiles(e.dataTransfer.files);
  }
}
