
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';
import Select from '../component/Select.vue';

export default defineComponent({
  components: { Select },
  props: {
    id: String,
    description: String,
  },

  async mounted() {
    if (this.id) {
      const x = await RestApi.preset.get(this.id);
      this.name = x.name;
      this.tags = x.tags;
    }
  },

  methods: {
    canSave(): boolean {
      return this.name.trim() === '';
    },
    async save() {
      try {
        if (this.id) {
          await RestApi.preset.update(this.id, this.name, this.tags);
        } else {
          await RestApi.preset.add(this.name, this.tags);
        }
        this.$emit('save');
        this.$emit('close');
      } catch (e) {
        alert(e.response.data.response);
      }
    },
  },

  data: () => {
    return {
      name: '',
      fieldsGeneral: [
        {
          name: 'fileName',
          type: 'string',
          hint: 'Example of completion: office_building_miami_001.jpeg',
        },
        { name: 'creator', type: 'string' },
        { name: 'headline', type: 'string' },
        { name: 'description', type: 'textarea' },
        { name: 'creditLine', type: 'string', title: 'Credit To' },
        { name: 'copyrightStatus', type: 'select', title: 'Сopyright Status' },
        { name: 'rightUsageTerms', type: 'string', title: 'Right Usage Terms' },
        { name: 'copyright', type: 'copyright' },

        {
          name: 'licensorUrl',
          type: 'string',
          title: 'Get This Image On',
          hint: 'https://example.com/photo.jpeg',
        },
        {
          name: 'webstatement',
          type: 'string',
          title: 'License Details',
          hint: 'https://example.com/CopyrightPolicy.pdf',
        },

        // { name: 'email', type: 'string' },
        //  { name: 'website', type: 'string' },
      ],
      fieldsAddress: [
        { name: 'country', type: 'string' },
        { name: 'state', type: 'string' },
        { name: 'city', type: 'string' },
        { name: 'postal', type: 'string' },
        { name: 'address', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'phone', type: 'string' },
        { name: 'website', type: 'string' },
      ],
      fieldsOther: [
        { name: 'latitude', type: 'number' },
        { name: 'longitude', type: 'number' },
        { name: 'keywords', type: 'string' },
      ],
      tags: {
        filename: '',
        creator: '',
        headline: '',
        description: '',
        creditLine: '',
        copyrightStatus: true,
        rightUsageTerms: '',
        copyright: ['© Copyright', `${new Date().getFullYear()}`, '', 'All Rights Reserved', ''],
        copyrightInfoUrl: '',
        email: '',
      },
    };
  },
});
