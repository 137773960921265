
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  async mounted() {
    this.refresh();
  },
  methods: {
    gotoResize() {
      this.$router.push(`/editor/${this.optimizationId}`);
    },
    async refresh() {
      this.optimizationId = this['$route'].params.optimizationId as string;
      const optimization = await RestApi.optimization.get(this.optimizationId);

      this.photoList = optimization.photoList;
    },
    async deletePhoto(id: string) {
      if (confirm('Are you sure?')) {
        await RestApi.optimization.deletePhoto(this.optimizationId, id);
        await this.refresh();
      }
    },
  },
  data: () => {
    return {
      photoList: [],
      optimizationId: '',
    };
  },
});
