
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  components: {},
  async mounted() {
    this.processingHistory = (await RestApi.user.processingHistory()).map((x: any) => {
      x.photoList = [];
      return x;
    });
    this.optimizationHistory = (await RestApi.user.optimizationHistory()).map((x: any) => {
      x.photoList = [];
      return x;
    });

    for (let i = 0; i < this.processingHistory.length; i++) {
      this.processingHistory[i].photoList = await RestApi.result.getPhotoList(
        this.processingHistory[i].id,
      );
    }

    for (let i = 0; i < this.optimizationHistory.length; i++) {
      this.optimizationHistory[i].photoList = await RestApi.optimization.getPhotoList(
        this.optimizationHistory[i].id,
      );
    }
  },
  methods: {},
  data: (): any => {
    return {
      type: 'processing',
      processingHistory: [],
      optimizationHistory: [],
    };
  },
});
/*@Options({
  components: {
    Back,
  },
})
export default class History extends Vue {
  resultHistory = [] as any[];
  optimizationHistory = [] as any[];
  type = 'result';

  async mounted() {
    const history = (await RestApi.user.resultHistory()) as any;
    this.resultHistory = history.result.map((x: any) => {
      x.created = Moment(x.created).fromNow();
      return x;
    });
    this.optimizationHistory = history.optimization.map((x: any) => {
      x.created = Moment(x.created).fromNow();
      return x;
    });
  }
}*/
