
import { defineComponent } from 'vue';
import { RestApi } from '../util/RestApi';

export default defineComponent({
  async mounted() {
    this.isLoading = true;

    // Get sub list
    this.subscriptionList = await RestApi.user.getSubscriptionList();

    this.productList = [
      /*{
        name: 'Free Account',
        price: 'Free',
        description: `10 credits`,
        canBuy: false,
        order: 0,
      },*/
      ...(await RestApi.store.getProductList()),
    ];
    this.productList.sort((a: any, b: any) => {
      return a.order - b.order;
    });

    // @ts-ignore
    /*this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    if (!this.stripe) {
      return;
    }
    const elements = this.stripe.elements();
    this.card = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          color: '#2b2b2b',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '18px',
          fontSmoothing: 'antialiased',
        },
      },
    });*/

    this.isLoading = false;
  },
  methods: {
    async subscribe(product: any) {
      this.isLoading = true;
      try {
        await RestApi.store.subscribe(product.priceId);
        window.location.reload();
      } catch (e) {
        alert(JSON.stringify(e, null, 4));
      }
      this.isLoading = false;

      // Set price
      /*this.currentPriceId = product.priceId;

      // @ts-ignore
      const user: any = this.$root?.user;
      if (user.stripeCustomerId && user.stripePaymentMethodId) {
        this.isPaying = true;
        await RestApi.store.subscribe(this.currentPriceId);
        this.isPaying = false;
        window.location.reload();
      } else {
        this.showPaymentForm();
      }*/
    },
    async pay(url: string) {
      window.location.href = url;
    },
    async changePlan(product: any) {
      await RestApi.store.changePlan(product.priceId);
      window.location.reload();
    },
    async unsubscribe(product: any) {
      this.isLoading = true;
      await RestApi.store.unsubscribe();
      window.location.reload();
      this.isLoading = false;
    },
    /*showPaymentForm() {
      this.isShowAddPaymentMethod = true;
      this.$nextTick(() => {
        this.card.mount('#card-elements');
      });
    },*/
    /*async addPayment() {
      this.isPaying = true;

      const { paymentMethod, error } = await this.stripe.createPaymentMethod('card', this.card, {});
      if (error) {
        alert(JSON.stringify(error, null, 4));
        this.isPaying = false;
      } else {
        try {
          await RestApi.store.addPaymentMethod(paymentMethod.id);
          await RestApi.store.subscribe(this.currentPriceId);
        } catch (e) {
          alert(JSON.stringify(e, null, 4));
          this.isPaying = false;
        }
      }

      this.isPaying = false;
    },*/
  },
  data: () => {
    return {
      isLoading: false,
      productList: [] as any[],
      subscriptionList: [],
    };
  },
});
