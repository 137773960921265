
import { defineComponent } from 'vue';
import { RestApi } from '../../util/RestApi';

export default defineComponent({
  components: {},
  async mounted() {},
  methods: {
    async auth() {
      this.isError = false;

      try {
        const accessToken = await RestApi.user.signUp(
          this.email,
          this.password_1,
          this.password_2,
          '',
          '',
          '',
        );
        localStorage.setItem('accessToken', accessToken);
        window.location.href = '/';
      } catch (e) {
        this.isError = true;
        // alert(e.response.data.description);
      }
    },
  },
  data: () => {
    return {
      isError: false,
      name: '',
      email: '',
      password_1: '',
      password_2: '',
    };
  },
});
