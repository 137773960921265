import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 3,
  class: "blue",
  style: {"margin-left":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")
  const _component_Confirm = _resolveComponent("Confirm")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$style.container
  }, [
    _createVNode("div", {
      class: _ctx.$style.field_block
    }, [
      (!_ctx.preset)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fields, (group) => {
              return (_openBlock(), _createBlock("div", {
                key: group.name
              }, [
                _createVNode("h2", null, _toDisplayString(group.name), 1),
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(group.items, (field) => {
                  return (_openBlock(), _createBlock("div", {
                    class: _ctx.$style.field,
                    key: field.name
                  }, [
                    _createVNode("div", {
                      class: _ctx.$style.field_description
                    }, _toDisplayString(field.title || field.name.replace(/\-/g, ' ')) + ": ", 3),
                    (field.type === 'textarea')
                      ? _withDirectives((_openBlock(), _createBlock("textarea", {
                          key: 0,
                          class: _ctx.$style.input,
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => (_ctx.tags[field.name] = $event)
                        }, null, 10, ["onUpdate:modelValue"])), [
                          [_vModelText, _ctx.tags[field.name]]
                        ])
                      : _createCommentVNode("", true),
                    (!field.isReadOnly && field.type === 'string')
                      ? _withDirectives((_openBlock(), _createBlock("input", {
                          key: 1,
                          class: _ctx.$style.input,
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => (_ctx.tags[field.name] = $event)
                        }, null, 10, ["onUpdate:modelValue"])), [
                          [_vModelText, _ctx.tags[field.name]]
                        ])
                      : _createCommentVNode("", true),
                    (field.isReadOnly && field.type === 'string')
                      ? (_openBlock(), _createBlock("div", {
                          key: 2,
                          class: [_ctx.$style.input, _ctx.$style.locked]
                        }, " For Agency and Pro plan only ", 2))
                      : _createCommentVNode("", true),
                    (field.type === 'number')
                      ? _withDirectives((_openBlock(), _createBlock("input", {
                          key: 3,
                          class: _ctx.$style.input,
                          type: "number",
                          "onUpdate:modelValue": ($event: any) => (_ctx.tags[field.name] = $event)
                        }, null, 10, ["onUpdate:modelValue"])), [
                          [_vModelText, _ctx.tags[field.name]]
                        ])
                      : _createCommentVNode("", true),
                    (field.type === 'copyright')
                      ? (_openBlock(), _createBlock("div", {
                          key: 4,
                          class: _ctx.$style.copyright
                        }, [
                          (_openBlock(), _createBlock(_Fragment, null, _renderList(['© Copyright', 'Year', 'Author', 'License', 'Document Name'], (x, i) => {
                            return _withDirectives(_createVNode("input", {
                              placeholder: x,
                              "onUpdate:modelValue": ($event: any) => (_ctx.tags[field.name][i] = $event),
                              key: x,
                              class: _ctx.$style.input
                            }, null, 10, ["placeholder", "onUpdate:modelValue"]), [
                              [_vModelText, _ctx.tags[field.name][i]]
                            ])
                          }), 64))
                        ], 2))
                      : _createCommentVNode("", true),
                    (field.type === 'select')
                      ? (_openBlock(), _createBlock(_component_Select, {
                          key: 5,
                          list: [
                { label: 'Copyright', value: true },
                { label: 'Public Domain', value: false },
              ],
                          type: "text",
                          modelValue: _ctx.tags[field.name],
                          "onUpdate:modelValue": ($event: any) => (_ctx.tags[field.name] = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true),
                    (field.hint)
                      ? (_openBlock(), _createBlock("div", {
                          key: 6,
                          class: _ctx.$style.field_hint
                        }, _toDisplayString(field.hint), 3))
                      : _createCommentVNode("", true)
                  ], 2))
                }), 128))
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.preset)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fields, (group) => {
              return (_openBlock(), _createBlock("div", {
                key: group.name
              }, [
                _createVNode("h2", null, _toDisplayString(group.name), 1),
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(group.items, (field) => {
                  return (_openBlock(), _createBlock("div", {
                    class: _ctx.$style.field,
                    key: field.name
                  }, [
                    _createVNode("div", {
                      class: _ctx.$style.field_description
                    }, _toDisplayString(field.title || field.name.replace(/\-/g, ' ')) + ": ", 3),
                    (field.type === 'textarea')
                      ? _withDirectives((_openBlock(), _createBlock("textarea", {
                          key: 0,
                          class: [_ctx.$style.input, _ctx.$style.input_border],
                          "onUpdate:modelValue": ($event: any) => (_ctx.preset[field.name] = $event)
                        }, null, 10, ["onUpdate:modelValue"])), [
                          [_vModelText, _ctx.preset[field.name]]
                        ])
                      : _createCommentVNode("", true),
                    (!field.isReadOnly && (field.type === 'string' || field.type === 'number'))
                      ? _withDirectives((_openBlock(), _createBlock("input", {
                          key: 1,
                          class: [_ctx.$style.input, _ctx.$style.input_border],
                          "onUpdate:modelValue": ($event: any) => (_ctx.preset[field.name] = $event)
                        }, null, 10, ["onUpdate:modelValue"])), [
                          [_vModelText, _ctx.preset[field.name]]
                        ])
                      : _createCommentVNode("", true),
                    (field.isReadOnly && (field.type === 'string' || field.type === 'number'))
                      ? (_openBlock(), _createBlock("div", {
                          key: 2,
                          class: [_ctx.$style.input, _ctx.$style.locked]
                        }, " For Agency and Pro plan only ", 2))
                      : _createCommentVNode("", true),
                    (field.type === 'copyright')
                      ? (_openBlock(), _createBlock("div", {
                          key: 3,
                          class: _ctx.$style.copyright
                        }, [
                          (_openBlock(), _createBlock(_Fragment, null, _renderList(['© Copyright', 'Year', 'Author', 'License', 'Document Name'], (x, i) => {
                            return _withDirectives(_createVNode("input", {
                              placeholder: x,
                              key: x,
                              class: [_ctx.$style.input, _ctx.$style.input_border],
                              "onUpdate:modelValue": ($event: any) => (_ctx.preset[field.name][i] = $event)
                            }, null, 10, ["placeholder", "onUpdate:modelValue"]), [
                              [_vModelText, _ctx.preset[field.name][i]]
                            ])
                          }), 64))
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 2))
                }), 128))
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode("div", {
        class: _ctx.$style.bottom,
        style: {"display":"flex"}
      }, [
        _createVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clear())),
          class: "white-empty",
          style: {"margin-left":"auto"}
        }, " Clear all fields "),
        (_ctx.checkChanges().length > 0)
          ? (_openBlock(), _createBlock("button", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save('amend'))),
              class: "blue",
              style: {"margin-left":"20px"}
            }, " Amend "))
          : _createCommentVNode("", true),
        (_ctx.checkChanges().length > 0)
          ? (_openBlock(), _createBlock("button", {
              key: 1,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save('overwrite'))),
              class: "blue",
              style: {"margin-left":"20px"}
            }, " Overwrite "))
          : _createCommentVNode("", true),
        (_ctx.checkChanges().length > 0)
          ? (_openBlock(), _createBlock("button", {
              key: 2,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancel())),
              class: "red",
              style: {"margin-left":"20px"}
            }, " Cancel "))
          : _createCommentVNode("", true),
        (_ctx.checkChanges().length <= 0)
          ? (_openBlock(), _createBlock("button", _hoisted_3, " Continue With Tags "))
          : _createCommentVNode("", true),
        (_ctx.checkChanges().length <= 0)
          ? (_openBlock(), _createBlock("button", {
              key: 4,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.back())),
              class: "blue",
              style: {"margin-left":"20px"}
            }, " Return To Optimize Editor "))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    (_ctx.isShowModal)
      ? (_openBlock(), _createBlock(_component_Confirm, {
          key: 0,
          onClose: _ctx.closeModal,
          onConfirm: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalConfirmFunction(true, ..._ctx.modalArgs))),
          message: "Are you sure? This action is irreversible"
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}