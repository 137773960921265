
import { defineComponent } from 'vue';
import { RestApi } from '../../util/RestApi';

export default defineComponent({
  async mounted() {
    this.refreshuserList();
  },
  methods: {
    async refreshuserList() {
      this.userEmailList = await RestApi.admin.getUserList();

      for (let i = 0; i < this.userEmailList.length; i++) {
        this.userList[i] = await RestApi.admin.getUser(this.userEmailList[i]);
        // this.loadUser(i, this.userEmailList[i]);
      }
    },
    async loadUser(index: number, email: string) {
      this.userList[index] = await RestApi.admin.getUser(email);
    },
    async setBalance(email: string) {
      const amount = prompt('How much to add?');
      if (amount) {
        await RestApi.admin.addBalance(email, ~~amount);
      }
      window.location.reload();
    },
    async setRole(email: string) {
      const role = prompt('Enter role name');
      if (role) {
        await RestApi.admin.setRole(email, role);
      }
      window.location.reload();
    },
  },
  data: () => {
    return {
      userEmailList: [] as any[],
      userList: [] as any[],
    };
  },
});
