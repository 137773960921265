import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Axios from 'axios';
import './main.scss';
import 'swiper/swiper.scss';
import 'aos/dist/aos.css';

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['Authorization'] = localStorage.getItem('accessToken');

createApp(App).use(router).mount('#app');
